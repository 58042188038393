@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.prompt-bold {
  font-family: "Prompt", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: large;
}
.work-calendar-overview {
  border-radius: 6px;
  background-color: white;
  margin: 18px;
}

.calendar-header {
  display: flex;
  gap: 2px;
  align-content: right;
  margin-bottom: 12px;
}

.calendar-header button {
  border: none;
  background-color: white;
  font-family: "Prompt", sans-serif;
  cursor: pointer;
}
.gray-button {
  color: #979595;
  font-weight: 500;
}

.gray-button:hover,
.gray-button:focus {
  color: black;
}

.light-gray-button {
  color: #c9c1c1;
}

.light-gray-button:hover,
.light-gray-button:focus {
  color: black;
  border-bottom: 2px solid #4c6fff;
}

.calendar-button {
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 20px 20px 20px 20px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: #c9c1c1;
  border: none;
  cursor: pointer;
}
.calendar-button.selected {
  background-color: #4c6fff !important;
  color: white !important;
}
.calendar-button span.date {
  color: black;
  font-weight: bold;
}

.calendar-button.selected span.date {
  color: white;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.event-list {
  margin-top: 5vh;
  cursor: pointer;
}

.table-header {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  color: #979595;
  margin-bottom: 10px;
  font-weight: bold;
  margin-top: 10px;
}
.table-body {
  max-height: 60vh;
  overflow: auto;
}
.event-item {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 15px;
  height: 4rem;
}
.event-item:nth-child(even) {
  background: #edf2f3;
}

.event-details {
  /* width: 20.5%; */
  display: flex;
  flex-direction: column;
}

.event-status {
  display: flex;
}

.event-status span {
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  margin: auto;
}

.event-client {
  width: 10%;
}
.span-grn {
  color: #4cceac;
  background-color: #dbf5ee;
}
.span-blue {
  color: #868dfb;
  background-color: #e1e2fe;
}
.span-pink {
  color: #e2726e;
  background-color: #f8eaee;
}
.span-purple {
  color: #535ac8;
  background-color: #ece6f0;
}
.edit-button {
  background: none;
  border: none;
  cursor: pointer;
}
.delete-button {
  background: none;
  cursor: pointer;
  border: none;
}
.attended-clients {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.attended-clients img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 10% !important;
}
.client-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.client-name {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.client-type {
  font-size: 14px;
  color: #c9c1c1;
  margin-top: 0;
}

.client-money {
  font-size: 14px;
  color: #40ac8f;
  font-weight: bold;
  background-color: #dbf5ee;
  padding: 5px 10px;
  border-radius: 5px;
}
.hide-pscrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.hide-pscrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
div.react-datepicker-popper {
  transform: translate(172.889px, 125.556px) !important;
}

.event-date {
  width: 98%;
  padding: 5px 19px;
  margin: 9px 0;
  margin-top: 2px;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #f1f2fa;
  outline: none;
  position: relative;
  border: none;
  border-radius: 8px;
  z-index: inherit;
  font-weight: bold;
}

.event-date:focus {
  outline: none;
}

.event-date:focus + .date-span {
  opacity: 1;
  background-color: transparent;
  transform: translateY(-100%) translateX(0%);
  font-size: 12px;
}

.date-span {
  font-size: 13px;
  font-weight: bold;
  padding: 0 0px;
  pointer-events: none;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.4s;
  opacity: 0.6;
  color: #999;
  padding: 6px 0;
}

.date-label {
  position: relative;
  display: block;
  width: 100%;
}
.date-span.stay-up {
  transform: translateY(-100%) translateX(0%);
  font-size: 12px;
  opacity: 1;
}
.profile-image-container {
  width: 40px; /* Adjust size as needed */
  height: 40px;
  border-radius: 50%;
  margin-left: -10px; /* Adjust overlap size as needed */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-image-container:first-child {
  margin-left: 0; /* No overlap for the first image */
}

.profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid white; /* Adjust border color and size as needed */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

.more-profiles {
  width: 40px;
  height: 40px;
  background-color: #e0e0e0; /* Adjust background color as needed */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555; /* Adjust text color as needed */
  font-weight: bold;
  font-size: 14px;
  margin-left: -10px;
}
