@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html,
body,
#root,
.app,
.content{
  height: 100%;
  width: 100%;
  font-family: 'Inter', sans-serif;
  margin: 0;
  /* display: flex; */
}

.app{
  display: flex;
  position: relative;
}

::-webkit-scrollbar{
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track{
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb{
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-track:hover{
  background: #555;
}


.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child,
.recharts-wrapper .recharts-cartesian-grid-vertical line:first-child,
.recharts-wrapper .recharts-cartesian-grid-vertical line:last-child {
  stroke-opacity: 0 !important;
}